import { React, Component } from 'react';
import styled from 'styled-components';
import CheckMark from '../../assets/svg/Checkmark';
import FullButton from '../../components/buttons/FullButton';
import { Link } from 'react-router-dom';

class CourseTable extends Component {
  render() {
    const { price, title, text, url, offers } = this.props;

    return (
      <Wrapper className="whiteBg radius8 shadow">
        <PriceHeader className="flexSpaceCenter">
          <p className="font30 extraBold">{price === 0 ? 'FREE' : `$${price}`}</p>
        </PriceHeader>
        <div style={{ margin: "30px 0" }}>
          <h4 className="font30 extraBold">{title}</h4>
          <p className="font13 semiBold">{text}</p>
        </div>
        <div>
          {offers
            ? offers.map((offer, index) => (
                <div className="flexNullCenter" style={{ margin: "15px 0" }} key={index}>
                  <div style={{ position: "relative", top: "-1px", marginRight: "15px" }}>
                    <div style={{ minWidth: "20px" }}>
                      { offer.checked === 'true' ? <CheckMark /> : null }
                    </div>
                  </div>
                  <p className="font20 extraBold">{offer.name}</p>
                </div>
              ))
            : null}
        </div>
        <div style={{ textAlign: "center" }}>
          <Link to={url}>
            <FullButton title="Buy Now"/>
          </Link>
        </div>
      </Wrapper>
    );
  }
}

export default CourseTable;

const Wrapper = styled.div`
  width: 100%;
  text-align: left;
  padding: 20px 30px;
  margin-top: 30px;
`;
const PriceHeader = styled.div`
  display: flex;
  align-items: flex-end;
`;