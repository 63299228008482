import React from "react";
import styled from "styled-components";

export default function FullButton({ title, action, border, ...props }) {
  return (
    <Wrapper
      className={"animate pointer radius8 font13 " + props.class}
      onClick={action ? () => action() : null}
      border={border}
    >
      {title}
    </Wrapper>
  );
}

const Wrapper = styled.button`
  border: 1px solid #00931d;
  background-color: ${(props) => (props.border ? "transparent" : "#00931d")};
  width: 100%;
  padding: 15px;
  outline: none;
  color: ${(props) => (props.border ? "#00931d" : "#fff")};
  &:hover {
    background-color: ${(props) => (props.border ? "transparent" : "#005e13")};
    border: 1px solid ${(props) => (props.border ? "#005e13" : "#00931d")};
    color: ${(props) => (props.border ? "#005e13" : "#fff")};
  };
`;