import { createSlice } from '@reduxjs/toolkit';

export const sidebar = createSlice({
  name: 'sidebar',
  initialState: { value: false },
  reducers: {
    toggleSidebar: (state, action) => {
        state.value = action.payload;
    }
  },
});

export const { toggleSidebar } = sidebar.actions;
export const selectSidebar = (state) => state.sidebar.value;

export default sidebar.reducer;