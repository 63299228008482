import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

// Methods
export const getTestimonials = createAsyncThunk(
  'testimonials/getTestimonials',
  async () => {
    try {
      const response = await axios.get('api/v1/testimonials/');
      return response.data;
    } catch (error) {
      console.error(error);
    }
});

export const testimonials = createSlice({
  name: 'testimonials',
  initialState: {
    testimonials: [],
    status: 'idle'
  },
  extraReducers: (builder) => {
    builder
      .addCase(getTestimonials.pending, (state, action) => {
        state.isLoading = 'loading';
      })
      .addCase(getTestimonials.fulfilled, (state, action) => {
        state.testimonials = action.payload;
        state.isLoading = 'success';
      })
      .addCase(getTestimonials.rejected, (state, action) => {
        state.isLoading = 'failed';
      });
  }
});

// Selectors
export const selectTestimonials = (state) => state.testimonials.testimonials;
export const selectLoadingState = (state) => state.testimonials.status;

export default testimonials.reducer;