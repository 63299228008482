import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

export const getFeatures = createAsyncThunk(
  'features/getFeatures',
  async () => {
    try {
      const response = await axios.get('api/v1/features/');
      return response.data;
    } catch (error) {
      console.error(error);
    }
});

export const features = createSlice({
  name: 'features',
  initialState: {
    features: {},
    status: 'idle',
    hasError: false
  },
  extraReducers: (builder) => {
    builder
      .addCase(getFeatures.pending, (state, action) => {
        state.isLoading = 'loading';
        state.hasError = false;
      })
      .addCase(getFeatures.fulfilled, (state, action) => {
        state.features = action.payload;
        state.isLoading = 'success';
        state.hasError = false
      })
      .addCase(getFeatures.rejected, (state, action) => {
        state.hasError = true
        state.isLoading = 'failed';
      });
  }
});

export const selectFeatures = (state) => state.features.features;
export const selectLoadingState = (state) => state.features.status;
export const selectErrorState = (state) => state.features.hasError;

export default features.reducer;