import { React, Component } from "react";
import styled from "styled-components";
import { Link } from "react-scroll";
// Assets
import CloseIcon from "../../assets/svg/CloseIcon";
import LogoIcon from "../../assets/svg/Logo";

class Sidebar extends Component {
  render() {
    const { sidebarOpen, toggleSidebar, dispatch, auth_enabled } = this.props;
    
    return (
      <Wrapper className="animate darkBg" sidebarOpen={sidebarOpen}>
        <SidebarHeader className="flexSpaceCenter">
          <LogoIcon />
          <CloseBtn onClick={() => dispatch(toggleSidebar(false))} className="animate pointer">
            <CloseIcon />
          </CloseBtn>
        </SidebarHeader>

        <UlStyle className="flexNullCenter flexColumn">
          <li className="semiBold font15 pointer">
            <Link
              onClick={() => dispatch(toggleSidebar(false))}
              activeClass="active"
              className="whiteColor"
              style={{ padding: "10px 15px" }}
              to="home"
              spy={true}
              smooth={true}
              offset={-80}
            >
              Home
            </Link>
          </li>
          <li className="semiBold font15 pointer">
            <Link
              onClick={() => dispatch(toggleSidebar(false))}
              activeClass="active"
              className="whiteColor"
              style={{ padding: "10px 15px" }}
              to="courses"
              spy={true}
              smooth={true}
              offset={-80}
            >
              Courses
            </Link>
          </li>
          {/* <li className="semiBold font15 pointer">
            <Link
              onClick={() => dispatch(toggleSidebar(false))}
              activeClass="active"
              className="whiteColor"
              style={{ padding: "10px 15px" }}
              to="consultations"
              spy={true}
              smooth={true}
              offset={-80}
            >
              Consultations
            </Link>
          </li> */}
          <li className="semiBold font15 pointer">
            <Link
              onClick={() => dispatch(toggleSidebar(false))}
              activeClass="active"
              className="whiteColor"
              style={{ padding: "10px 15px" }}
              to="community"
              spy={true}
              smooth={true}
              offset={-80}
            >
              Community
            </Link>
          </li>
          <li className="semiBold font15 pointer">
            <Link
              onClick={() => dispatch(toggleSidebar(false))}
              activeClass="active"
              className="whiteColor"
              style={{ padding: "10px 15px" }}
              to="testimonials"
              spy={true}
              smooth={true}
              offset={-80}
            >
              Testimonials
            </Link>
          </li>
          <li className="semiBold font15 pointer">
            <Link
              onClick={() => dispatch(toggleSidebar(false))}
              activeClass="active"
              className="whiteColor"
              style={{ padding: "10px 15px" }}
              to="why-us"
              spy={true}
              smooth={true}
              offset={-80}
            >
              Why Us
            </Link>
          </li>
          <li className="semiBold font15 pointer">
            <Link
              onClick={() => dispatch(toggleSidebar(false))}
              activeClass="active"
              className="whiteColor"
              style={{ padding: "10px 15px" }}
              to="contact"
              spy={true}
              smooth={true}
              offset={-80}
            >
              Contact
            </Link>
          </li>
        </UlStyle>
        { auth_enabled ?
          <UlStyle className="flexSpaceCenter">
            <li className="semiBold font15 pointer">
              <a href="/" style={{ padding: "10px 30px 10px 0" }} className="whiteColor">
                Log in
              </a>
            </li>
            <li className="semiBold font15 pointer flexCenter">
              <a href="/" className="radius8 lightBg" style={{ padding: "10px 15px" }}>
                Get Started
              </a>
            </li>
          </UlStyle>
        : null }
      </Wrapper>
    );
  }
}

export default Sidebar;

const Wrapper = styled.nav`
  width: 400px;
  height: 100%;
  position: fixed;
  top: 0;
  padding: 0 10px;
  right: ${(props) => (props.sidebarOpen ? "0px" : "-859px")};
  z-index: 9999;
  @media only screen and (max-width: 859px) {
    width: 90%;
  }
`;
const SidebarHeader = styled.div`
  padding: 20px 0;
`;
const CloseBtn = styled.button`
  border: 0px;
  outline: none;
  background-color: transparent;
  padding: 40px;
`;
const UlStyle = styled.ul`
  padding: 40px;
  li {
    margin: 20px 0;
  }
`;