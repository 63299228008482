import { configureStore } from '@reduxjs/toolkit';
import sidebar from './slices/sidebarSlice';
import services from './slices/servicesSlice';
import courses from './slices/coursesSlice';
import testimonials from './slices/testimonialsSlice';
import contact from './slices/contactSlice';
import features from './slices/featureSlice';
import calendly from './slices/calendlySlice';

export const store = configureStore({
  reducer: {
    sidebar: sidebar,
    services: services,
    courses: courses,
    testimonials: testimonials,
    contact: contact,
    features: features,
    calendly: calendly
  }
});

export default store;