import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { store } from './app/store';
import { Provider } from 'react-redux';
import axios from 'axios';
import theme from './theme';
import { ThemeProvider } from '@mui/material/styles';
import './styles/index.css';
import './styles/errors.css';
import './styles/flexboxgrid.min.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';


axios.defaults.baseURL = process.env.NODE_ENV === 'development' ?
  'http://127.0.0.1:8000/' :
  'https://flexin-financials-server-77d530667631.herokuapp.com/';
const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <Provider store={store}>
        <App />
      </Provider>
    </ThemeProvider>
  </React.StrictMode>
);