import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

export const getCourses = createAsyncThunk(
  'courses/getCourses',
  async () => {
    try {
      const response = await axios.get('api/v1/courses/');
      return response.data;
    } catch (error) {
      console.error(error);
    }
});

export const courses = createSlice({
  name: 'courses',
  initialState: {
    courses: [],
    status: 'idle'
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCourses.pending, (state, action) => {
        state.isLoading = 'loading';
      })
      .addCase(getCourses.fulfilled, (state, action) => {
        state.courses = action.payload;
        state.isLoading = 'success';
      })
      .addCase(getCourses.rejected, (state, action) => {
        state.isLoading = 'failed';
      });
  }
});

export const selectCourses = (state) => state.courses.courses;
export const selectLoadingState = (state) => state.courses.status;

export default courses.reducer;