import { React, Component } from 'react';
import Header from './sections/Header';
import Services from './sections/Services';
import Courses from './sections/Courses';
import Consultations from './sections/Consultations';
import Community from './sections/Community';
import Testimonials from './sections/Testimonials';
import Contact from './sections/Contact';

class Home extends Component {
  render() {
    return (
      <>
        <Header />
        <Courses />
        {/* <Consultations /> */}
        <Community />
        <Testimonials />
        <Services />
        <Contact />
      </>
    );
  }
}

export default Home;