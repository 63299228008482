import { React, Component, useEffect } from "react";
import { useSelector, useDispatch } from 'react-redux';
import {
  getTestimonials,
  selectTestimonials,
  selectLoadingState
} from '../../../app/slices/testimonialsSlice';
// Components
import styled from "styled-components";
import TestimonialSlider from "../../../components/elements/TestimonialSlider";

const withHooks = (WrappedComponent) => {
	return function(props) {
		const testimonials = useSelector(selectTestimonials);
    const isLoading = useSelector(selectLoadingState);
    const dispatch = useDispatch();

    useEffect(() => {
      if (isLoading === 'idle') {
        dispatch(getTestimonials());
      }
    }, [isLoading, dispatch]);
    
		return (<Testimonials testimonials={testimonials} />);
	};
}

class Testimonials extends Component {
  render() {
    const { testimonials } = this.props;

    return (
      <Wrapper id="testimonials">
        <div className="whiteBg" style={{ padding: '60px 0' }}>
          <InnerWrapper className="container">
            <HeaderInfo>
              <h1 className="font40 extraBold greenColor">Customer Testimonials</h1>
              <p className="font13 semiBold">
                Don't take our word for it. See what actual customers are saying about the courses.
              </p>
            </HeaderInfo>
            <TestimonialSlider testimonials={testimonials} />
          </InnerWrapper>
        </div>
      </Wrapper>
    );
  }
}

export default withHooks(Testimonials);

const Wrapper = styled.section`
  width: 100%;
`;
const InnerWrapper = styled.div`
  @media (max-width: 859px) {
    height: 600px;
  }
`;
const HeaderInfo = styled.div`
  padding-bottom: 30px;
  @media (max-width: 859px) {
    text-align: center;
  }
`;