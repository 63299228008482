import { React, Component } from 'react';
import { Link } from 'react-router-dom';
import FullButton from '../../components/buttons/FullButton';
import styled from 'styled-components';

class NotFoundPage extends Component {
  render() {
    return (
      <Wrapper>
        <div class="error-container">
          <span class="four"><span class="screen-reader-text">4</span></span>
          <span class="zero"><span class="screen-reader-text">0</span></span>
          <span class="four"><span class="screen-reader-text">4</span></span>
        </div>
        <Heading>This page does not exist.</Heading>
        <p class="zoom-area"><b>OOPS!</b> Looks like you're lost.</p>
        <div className="flexCenter">
          <Link to="/">
            <FullButton title="Visit homepage" />
          </Link>
        </div>
      </Wrapper>
    );
  }
}

export default NotFoundPage;

const Wrapper = styled.section`
  width: 100%;
`;
const Heading = styled.h1`
  text-align: center;
  margin: 30px 15px;
`;