import { React, Component } from "react";
import styled from "styled-components";
// Assets
import QuoteIcon from "../../assets/svg/Quotes";

class TestimonialBox extends Component {
  render() {
    const { text, author } = this.props;

    return (
      <Wrapper className="darkBg radius8 flexNullCenter flexColumn">
        <QuoteWrapper>
          <QuoteIcon />
        </QuoteWrapper>
        <p className="whiteColor font13" style={{ paddingBottom: "30px" }}>
          {text}
        </p>
        <p className="greenColor font13" style={{alignSelf: 'flex-end'}}>
          <em>{author}</em>
        </p>
      </Wrapper>
    );
  }
}

export default TestimonialBox;

const Wrapper = styled.div`
  width: 100%;
  padding: 20px 30px;
  margin-top: 30px;
`;
const QuoteWrapper = styled.div`
  position: relative;
  top: -40px;
`;