import { React, Component } from "react";
import Slider from "react-slick";
import styled from "styled-components";
import Alert from '@mui/material/Alert';
import TestimonialBox from "../elements/TestimonialBox";

class TestimonialSlider extends Component {
  render() {
    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 3,
      arrows: false,
      responsive: [
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    };

    const { testimonials } = this.props;
    
    return (
      <div>
        { Array.isArray(testimonials) && testimonials.length > 0 ? <Slider {...settings}>
          { testimonials.map((data, index) => (
            <LogoWrapper key={index} className="flexCenter">
              <TestimonialBox
                text={data.text}
                author={data.author}
              />
            </LogoWrapper>
          ))}
        </Slider> : <Alert severity="info">Testimonials not available at this time.</Alert>}
      </div>
    );
  }
}

export default TestimonialSlider;

const LogoWrapper = styled.div`
  width: 100%;
  padding: 0 5%;
  cursor: pointer;
  margin-bottom: 30px;
`;