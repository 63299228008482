import { React, Component } from "react";
import styled from "styled-components";

class Backdrop extends Component {
  render() {
    const { toggleSidebar } = this.props;

    return <Wrapper className="animate darkBg" onClick={() => toggleSidebar(false)}></Wrapper>;
  }
}

export default Backdrop;

const Wrapper = styled.div`
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  opacity: 0.8;
`;