import { React, Component } from "react";
import Typography from '@mui/material/Typography';
import { getCurrentYear } from '../utils/utils';

class Copyright extends Component {
	render() {
		return (
			<Typography variant="body3">
				Copyright © Flexin Financials, LLC { getCurrentYear() }
			</Typography>
		);
	}
}

export default Copyright;