import { React, Component } from 'react';
import { Link } from 'react-scroll';
import styled from 'styled-components';
import FullButton from '../../../components/buttons/FullButton';
import AddImage1 from '../../../assets/img/add/ServiceImg1.png';
import AddImage2 from '../../../assets/img/add/ServiceImg2.png';
import AddImage3 from '../../../assets/img/add/ServiceImg3.png';
import AddImage4 from '../../../assets/img/add/ServiceImg4.png';

class Services extends Component {
  render() {
    return (
      <Wrapper id="why-us">
        <div className="lightBg" style={{ padding: "60px 0" }}>
          <div className="lightBg">
            <div className="container">
              <Advertising className="flexSpaceCenter">
                <AddLeft>
                  <h2 className="font40 extraBold greenColor">Why Flexin Financials?</h2>
                  <p className="font12 semiBold">
                    Our goal is to provide intuitive and "no BS" financial education for people of all experience levels.
                    Whether you have no idea what the stock market is at all,
                    you want to learn how to properly prepare for retirement,
                    or you want to learn advanced trading techniques,
                    Flexin Financials has the perfect resources for you.
                  </p>
                  <ButtonsRow className="flexNullCenter" style={{ margin: "30px 0"}}>
                    <Link style={{ width: "190px" }} to="contact" spy={true} smooth={true} offset={-80}>
                      <FullButton title="Contact Us" border />
                    </Link>
                  </ButtonsRow>
                </AddLeft>
                <AddRight>
                  <AddRightInner>
                    <div className="flexNullCenter">
                      <AddImgWrapp1 className="flexCenter">
                        <img src={AddImage1} alt="office" />
                      </AddImgWrapp1>
                      <AddImgWrapp2>
                        <img src={AddImage2} alt="office" />
                      </AddImgWrapp2>
                    </div>
                    <div className="flexNullCenter">
                      <AddImgWrapp3>
                        <img src={AddImage3} alt="office" />
                      </AddImgWrapp3>
                      <AddImgWrapp4>
                        <img src={AddImage4} alt="office" />
                      </AddImgWrapp4>
                    </div>
                  </AddRightInner>
                </AddRight>
              </Advertising>
            </div>
          </div>
        </div>
      </Wrapper>
    );
  }
}

export default Services;

const Wrapper = styled.section`
  width: 100%;
`;
const Advertising = styled.div`
  margin: 60px 0;
  padding: 90px 0;
  position: relative;
  @media (max-width: 860px) {
    flex-direction: column;
    padding: 0 0 30px 0;
    margin: 60px 0 -45px 0;
  }
`;
const ButtonsRow = styled.div`
  @media (max-width: 860px) {
    flex-direction: column;
  }
`;
const AddLeft = styled.div`
  width: 50%;
  p {
    max-width: 475px;
  }
  @media (max-width: 860px) {
    width: 80%;
    order: 2;
    text-align: center;
    h2 {
      line-height: 3rem;
      margin: 15px 0;
    }
    p {
      margin: 0 auto;
    }
  }
`;
const AddRight = styled.div`
  width: 50%;
  position: absolute;
  top: -70px;
  right: 0;
  @media (max-width: 860px) {
    width: 80%;
    position: relative;
    order: 1;
    top: -40px;
  }
`;
const AddRightInner = styled.div`
  width: 100%;
`;
const AddImgWrapp1 = styled.div`
  width: 48%;
  margin: 0 6% 10px 6%;
  img {
    width: 100%;
    height: auto;
    border-radius: 1rem;
    box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
  }
`;
const AddImgWrapp2 = styled.div`
  width: 30%;
  margin: 0 5% 10px 5%;
  img {
    width: 100%;
    height: auto;
    border-radius: 1rem;
    box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
  }
`;
const AddImgWrapp3 = styled.div`
  width: 20%;
  margin-left: 40%;
  img {
    width: 100%;
    height: auto;
    border-radius: 1rem;
    box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
  }
`;
const AddImgWrapp4 = styled.div`
  width: 30%;
  margin: 0 5%auto;
  img {
    width: 100%;
    height: auto;
    border-radius: 1rem;
    box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
  }
`;