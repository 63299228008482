import { React, Component, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';
import TextField from '@mui/material/TextField';
import Alert from '@mui/material/Alert';
import LinearProgress from '@mui/material/LinearProgress';
import FullButton from '../../../components/buttons/FullButton';
import {
  selectStatus,
  selectErrors,
  resetForm,
  setErrors,
  setStatus
} from '../../../app/slices/contactSlice';
import axios from 'axios';

const withHooks = (WrappedComponent) => {
	return function(props) {
    const status = useSelector(selectStatus);
    const errors = useSelector(selectErrors);
    const dispatch = useDispatch()
    const timerRef = useRef();

    const handleLoad = (emailStatus) => {
      dispatch(setStatus('loading'));

      timerRef.current = window.setTimeout(() => {
        dispatch(setStatus(emailStatus));
      }, 2000);
    };

    const sendEmail = () => {
      const data = new FormData(document.getElementById('contact-form'));

      axios.post('api/v1/contact/', {
        name: data.get('name'),
        email: data.get('email'),
        message: data.get('message')
      })
        .then(function (response) {
          console.log(response);
          handleLoad('success');
          dispatch(resetForm());
        })
        .catch(function (error) {
          handleLoad('failed');
          dispatch(setErrors(error.response.data));
        });
      
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
    };
    
		return (<Contact status={status} errors={errors} sendEmail={sendEmail} />);
	};
}

class Contact extends Component {
  render() {
    const { status, errors, sendEmail } = this.props;

    return (
      <Wrapper id="contact">
        <div className="whiteBg" style={{ padding: '60px 0' }}>
          <div className="container">
            <HeaderInfo>
              <h1 className="font40 extraBold greenColor">Have a question for us?</h1>
              <p className="font13 semiBold">
                Send us a message and we will get back to you within 48 hours.
              </p>
            </HeaderInfo>
            <div className="row">
              <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                <div style={{ marginTop: '30px'}}>
                  { status === 'success' ?
                    <Alert severity="success">Email successfully sent!</Alert>
                  : null }
                  { status === 'failed' ?
                    <Alert severity="error">Correct your input below!</Alert>
                  : null }
                </div>
                <Form id="contact-form">
                  <div>
                    <TextField
                      label="Name"
                      name="name"
                      placeholder="Your name"
                      required
                      fullWidth
                      variant="standard"
                      margin="normal"
                      color="primary"
                      helperText={errors.name}
                    />
                    <TextField
                      label="Email"
                      name="email"
                      placeholder="yourname@example.com"
                      required
                      fullWidth
                      variant="standard"
                      margin="normal"
                      color="primary"
                      helperText={errors.email}
                    />
                  </div>
                  <div>
                    <TextField
                      label="Message"
                      name="message"
                      placeholder="Your message here..."
                      multiline
                      required
                      fullWidth
                      variant="standard"
                      margin="normal"
                      color="primary"
                      helperText={errors.message}
                    />
                  </div>
                </Form>
                { status === 'loading' ?
                <LinearProgress color="primary"/> :
                <SumbitWrapper>
                  <FullButton title="Send message" action={sendEmail} />
                </SumbitWrapper> }
              </div>
            </div>
          </div>
        </div>
      </Wrapper>
    );
  }
}

export default withHooks(Contact);

const Wrapper = styled.section`
  width: 100%;
`;
const HeaderInfo = styled.div`
  @media only screen and (max-width: 859px) {
    text-align: center;
  }
`;
const Form = styled.form`
  padding: 30px 0;
  input,
  textarea {
    width: 100%;
  }
  textarea {
    min-height: 100px;
  }
  @media only screen and (max-width: 859px) {
    padding: 30px 0;
  }
`;
const SumbitWrapper = styled.div`
  @media (max-width: 991px) {
    width: 100%;
    margin-bottom: 30px;
  }
`;