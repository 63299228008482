import { React, Component } from 'react';
import { Link } from 'react-scroll';
import styled from 'styled-components';
import FullButton from '../../../components/buttons/FullButton';
import HeaderImage from '../../../assets/img/HeaderImg.png';
import QuotesIcon from '../../../assets/svg/Quotes';
import Dots from '../../../assets/svg/Dots';

class Header extends Component {
  render() {
    return (
      <Wrapper id="home" className="container flexSpaceCenter">
        <LeftSide className="flexCenter">
          <div>
            <h1 className="extraBold font60">
              Welcome to<br/>Flexin Financials
            </h1>
            <HeaderP className="font13 semiBold">
              The ultimate place for trading & investing education, along with tips to level up your
              career, business, and overall life.
              <br />
              <br />
              <em>Monetize. Exercise. Mind your business.</em>
            </HeaderP>
            <BtnWrapper>
              <Link to="services" spy={true} smooth={true} offset={-80}>
                <FullButton title="Get Started" />
              </Link>
            </BtnWrapper>
          </div>
        </LeftSide>
        <RightSide>
          <ImageWrapper>
            <Img className="radius8" src={HeaderImage} alt="office" style={{zIndex: 9}} />
            <QuoteWrapper className="flexCenter darkBg radius8">
              <QuotesWrapper>
                <QuotesIcon />
              </QuotesWrapper>
              <div>
                <p className="font15 whiteColor">
                  <em>
                    Money isn't the most important thing in the world,
                    but it allows you to focus on the things that are important.
                    So, let's get to the money.
                  </em>
                </p>
                <p className="font13 greenColor extraBold textRight" style={{marginTop: '10px'}}>Cam, Founder & CEO</p>
              </div>
            </QuoteWrapper>
            <DotsWrapper>
              <Dots />
            </DotsWrapper>
          </ImageWrapper>
          <GreyDiv className="lightBg"></GreyDiv>
        </RightSide>
      </Wrapper>
    );
  }
}

export default Header;

const Wrapper = styled.section`
  padding-top: 60px;
  width: 100%;
  min-height: 840px;
  @media (max-width: 960px) {
    flex-direction: column;
  }
`;
const LeftSide = styled.div`
  width: 50%;
  height: 100%;
  @media (max-width: 960px) {
    width: 100%;
    margin: 50px 0;
    text-align: center;
  }
  @media (max-width: 560px) {
    margin: 60px 0;
  }
`;
const RightSide = styled.div`
  width: 50%;
  height: 100%;
  @media (max-width: 960px) {
    width: 100%;
    margin-top: 30px;
    margin-bottom: 90px;
  }
`;
const HeaderP = styled.div`
  max-width: 470px;
  padding: 15px 0 50px 0;
  line-height: 1.5rem;
  @media (max-width: 960px) {
    padding: 15px 0 50px 0;
    text-align: center;
    max-width: 100%;
  }
`;
const BtnWrapper = styled.div`
  max-width: 190px;
  @media (max-width: 960px) {
    margin: 0 auto;
  }
`;
const GreyDiv = styled.div`
  width: 30%;
  height: 700px;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 0;
  @media (max-width: 960px) {
    display: none;
  }
`;
const ImageWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  position: relative;
  z-index: 9;
  @media (max-width: 960px) {
    width: 100%;
    justify-content: center;
    margin-bottom: 30px;
  }
`;
const Img = styled.img`
  @media (max-width: 560px) {
    width: 80%;
    height: auto;
  }
`;
const QuoteWrapper = styled.div`
  position: absolute;
  left: 0;
  bottom: 50px;
  max-width: 330px;
  padding: 30px;
  z-index: 99;
  @media (max-width: 960px) {
    left: 20px;
    bottom: -50px;
  }
`;
const QuotesWrapper = styled.div`
  position: absolute;
  left: -20px;
  top: -10px;
`;
const DotsWrapper = styled.div`
  position: absolute;
  right: -100px;
  bottom: 100px;
  z-index: 2;
  @media (max-width: 960px) {
    right: 100px;
  }
  @media (max-width: 560px) {
    display: none;
  }
`;