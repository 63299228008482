import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

// Methods
export const getServices = createAsyncThunk(
  'services/getServices',
  async () => {
    try {
      const response = await axios.get('api/v1/services/');
      return response.data;
    } catch (error) {
      console.error(error);
    }
});

export const services = createSlice({
  name: 'services',
  initialState: {
    services: [],
    status: 'idle'
  },
  extraReducers: (builder) => {
    builder
      .addCase(getServices.pending, (state, action) => {
        state.isLoading = 'loading';
      })
      .addCase(getServices.fulfilled, (state, action) => {
        state.services = action.payload;
        state.isLoading = 'success';
      })
      .addCase(getServices.rejected, (state, action) => {
        state.isLoading = 'failed';
      });
  }
});

// Selectors
export const selectServices = (state) => state.services.services;
export const selectLoadingState = (state) => state.services.status;

export default services.reducer;