import * as React from "react";

function SvgComponent() {
  return (
    <svg version="1.0" xmlns="http://www.w3.org/2000/svg" width="100" height="100" viewBox="0 0 1080 1080">
      <g transform="translate(0, 1080) scale(0.1,-0.1)" fill="#00931d">
        <path d="M6203 6758 c-125 -105 -285 -239 -355 -297 l-127 -106 171 -3 c129
        -2 169 -5 165 -15 -2 -6 -30 -93 -62 -192 -31 -99 -85 -265 -120 -370 -35
        -104 -118 -358 -185 -564 -80 -246 -125 -369 -130 -360 -5 8 -45 120 -89 249
        -44 129 -104 305 -134 390 -29 85 -84 244 -122 353 l-67 197 -280 -2 -280 -3
        -62 -190 c-35 -104 -118 -359 -185 -565 -68 -206 -147 -447 -176 -535 -30 -88
        -104 -315 -166 -505 -62 -190 -114 -351 -117 -358 -3 -10 50 -12 259 -10 l263
        3 98 285 c54 157 130 377 169 490 39 113 100 294 137 403 37 108 69 197 72
        197 3 0 44 -121 93 -268 48 -147 136 -415 195 -597 l109 -330 281 -3 c189 -1
        282 1 282 8 0 11 189 566 480 1410 94 272 200 582 236 687 l66 193 163 2 163
        3 -77 91 c-132 158 -432 504 -436 504 -2 0 -106 -86 -232 -192z"/>
      </g>
    </svg>
  );
}

export default SvgComponent;