import { React, Component, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  setEventHeight,
  setEventsHeight,
  getCalendlyEventCount,
  selectHeight,
  selectLoadingState
} from '../../../app/slices/calendlySlice';
import { useCalendlyEventListener, InlineWidget } from "react-calendly";
import styled from 'styled-components';

const withHooks = (WrappedComponent) => {
	return function(props) {
		const widgetHeight = useSelector(selectHeight);
    const isLoading = useSelector(selectLoadingState);
    const dispatch = useDispatch();

    useEffect(() => {
      if (isLoading === 'idle') {
        dispatch(getCalendlyEventCount());
      }
    }, [isLoading, dispatch]);

    useCalendlyEventListener({
      onProfilePageViewed: () => dispatch(setEventsHeight()),
      onEventTypeViewed: () => dispatch(setEventHeight()),
      onDateAndTimeSelected: () => dispatch(setEventHeight()),
      onEventScheduled: () => dispatch(setEventsHeight())
    });
    
		return (<Consultations widgetHeight={widgetHeight} />);
	};
}

class Consultations extends Component {
  render() {
    const { widgetHeight } = this.props;

    return (
      <Wrapper id="consultations">
        <div className="whiteBg" style={{ padding: "60px 0" }}>
          <div className="container">
            <HeaderInfo>
              <h1 className="font40 extraBold greenColor">1 on 1 Consultations</h1>
              <p className="font13 semiBold">
                Looking for a more <strong>personal</strong> feel? 
                Check out our 1 on 1 consultations where you will receive private mentorship 
                catered to your specific needs and financial situation.
              </p>
            </HeaderInfo>
            <WidgetWrapper>
              <InlineWidget
                url="https://calendly.com/flexinfinancials?hide_gdpr_banner=1"
                styles={{
                  height: widgetHeight,
                  minWidth: '320px'
                }}
                pageSettings={{
                  backgroundColor: '#fff',
                  hideEventTypeDetails: false,
                  hideLandingPageDetails: true
                }}
              />
            </WidgetWrapper>
          </div>
        </div>
      </Wrapper>
    );
  }
}

export default withHooks(Consultations);

const Wrapper = styled.section`
  width: 100%;
`;
const WidgetWrapper = styled.div`
  height: ${(props) => props.widgetHeight}
  @media (max-width: 859px) {
    height: ${(props) => props.widgetHeight},
    minWidth: '320px'
  }
`;
const HeaderInfo = styled.div`
  @media (max-width: 859px) {
    text-align: center;
    padding: 0 0 60px 0;
  }
`;