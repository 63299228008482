import { React, Component } from 'react';
import { useSelector, useDispatch } from 'react-redux';
// import { Link } from 'react-router-dom';
import { Link } from 'react-scroll';
import { toggleSidebar, selectSidebar } from '../../app/slices/sidebarSlice';
import styled from 'styled-components';
import Sidebar from './Sidebar';
import Backdrop from '../elements/Backdrop';
import LogoIcon from '../../assets/svg/Logo';
import BurgerIcon from '../../assets/svg/BurgerIcon';


const withHooks = (WrappedComponent) => {
	return function(props) {
		const sidebarOpen = useSelector(selectSidebar);
		const dispatch = useDispatch();

		return (<Navbar sidebarOpen={sidebarOpen} dispatch={dispatch} />);
	};
}

class Navbar extends Component {
	render() {
		const { sidebarOpen, dispatch } = this.props;
    const auth_enabled = false;

    return (
			<>
        <Sidebar
          sidebarOpen={sidebarOpen}
          toggleSidebar={toggleSidebar}
          dispatch={dispatch}
          auth_enabled={auth_enabled} />
        { sidebarOpen && <Backdrop toggleSidebar={toggleSidebar} /> }
        <Wrapper className="flexCenter whiteBg shadow" style={{ height: "80px" }}>
          <div className="container flexSpaceCenter">
            <Link id="nav-logo-icon" className="pointer flexNullCenter" to="home" smooth={true}>
              <LogoIcon />
            </Link>
            <h1 className="font20 extraBold">
              Flexin Financials
            </h1>
            <BurgerWrapper className="pointer" onClick={() => dispatch(toggleSidebar(true))}>
              <BurgerIcon />
            </BurgerWrapper>
            <UlWrapper className="flexNullCenter">
              <li className="semiBold font15 pointer">
                <Link activeClass="active" style={{ padding: "5px 15px" }} to="home" spy={true} smooth={true} offset={-80}>
                  Home
                </Link>
              </li>
              <li className="semiBold font15 pointer">
                <Link activeClass="active" style={{ padding: "10px 15px" }} to="courses" spy={true} smooth={true} offset={-80}>
                  Courses
                </Link>
              </li>
              {/* <li className="semiBold font15 pointer">
                <Link activeClass="active" style={{ padding: "10px 15px" }} to="consultations" spy={true} smooth={true} offset={-80}>
                  Consultations
                </Link>
              </li> */}
              <li className="semiBold font15 pointer">
                <Link activeClass="active" style={{ padding: "10px 15px" }} to="community" spy={true} smooth={true} offset={-80}>
                  Community
                </Link>
              </li>
              <li className="semiBold font15 pointer">
                <Link activeClass="active" style={{ padding: "10px 15px" }} to="testimonials" spy={true} smooth={true} offset={-80}>
                  Testimonials
                </Link>
              </li>
              <li className="semiBold font15 pointer">
                <Link activeClass="active" style={{ padding: "10px 15px" }} to="why-us" spy={true} smooth={true} offset={-80}>
                  Why Us
                </Link>
              </li>
              <li className="semiBold font15 pointer">
                <Link activeClass="active" style={{ padding: "10px 15px" }} to="contact" spy={true} smooth={true} offset={-80}>
                  Contact
                </Link>
              </li>
            </UlWrapper>
            { auth_enabled ?
              <UlWrapperRight className="flexNullCenter">
                <li className="semiBold font15 pointer">
                  <a href="/" style={{ padding: "10px 30px 10px 0" }}>
                    Log in
                  </a>
                </li>
                <li className="semiBold font15 pointer flexCenter">
                  <a href="/" className="radius8 lightBg" style={{ padding: "10px 15px" }}>
                    Get Started
                  </a>
                </li>
              </UlWrapperRight>
            : null }
          </div>
        </Wrapper>
      </>
		);
  }
}

export default withHooks(Navbar);

const Wrapper = styled.nav`
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99;
`;
const BurgerWrapper = styled.button`
  outline: none;
  border: 0px;
  background-color: transparent;
  height: 100%;
  padding: 0 40px;
  display: none;
  @media only screen and (max-width: 859px) {
    display: block;
  }
`;
const UlWrapper = styled.ul`
  display: flex;
  @media only screen and (max-width: 859px) {
    display: none;
  }
`;
const UlWrapperRight = styled.ul`
  @media only screen and (max-width: 859px) {
    display: none;
  }
`;