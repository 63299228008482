import { createSlice } from '@reduxjs/toolkit';

export const contact = createSlice({
  name: 'contact',
  initialState: {
    errors: {},
    status: '',
  },
  reducers: {
    resetForm: (state) => {
      state.errors = {};
    },
    setErrors: (state, action) => {
      state.errors = action.payload;
    },
    setStatus: (state, action) => {
      state.status = action.payload;
    }
  },
});

export const { resetForm, setErrors, setStatus } = contact.actions;
export const selectStatus = (state) => state.contact.status;
export const selectErrors = (state) => state.contact.errors;

export default contact.reducer;