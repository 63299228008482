import { React, Component } from "react";
// Components
import styled from "styled-components";

class Community extends Component {
  render() {
    return (
      <Wrapper id="community">
        <div className="lightBg" style={{ padding: '60px 0' }}>
          <div className="container">
            <HeaderInfo>
              <h1 className="font40 extraBold greenColor">Investing & Trading Community</h1>
              <p className="font13 semiBold">
                Join the <strong>FREE</strong> Profits In PJs Discord community to connect and grow
                with like-minded traders and investors.
              </p>
            </HeaderInfo>
            <iframe
              title="discord"
              src="https://discord.com/widget?id=804574279818805248&theme=dark"
              width="100%"
              height="500"
              allowtransparency="true"
              frameborder="0"
              sandbox="allow-popups allow-popups-to-escape-sandbox allow-same-origin allow-scripts">
            </iframe>
          </div>
        </div>
      </Wrapper>
    );
  }
}

export default Community;

const Wrapper = styled.section`
  width: 100%;
`;
const HeaderInfo = styled.div`
  margin-bottom: 30px;
  @media (max-width: 860px) {
    text-align: center;
    margin-bottom: 60px;
  }
`;