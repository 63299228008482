import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  // Colors
  palette: {
    primary: {
      main: '#00931D',
      // light: will be calculated from palette.primary.main,
      // dark: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contrast with palette.primary.main
    },
  },
  // Components
  components: {
    MuiDivider: {
      styleOverrides: {
        root: {
          background: '#fff',
          borderBottomWidth: 2,
        },
      },
    },
  },
});

export default theme;