import { React, Component, useEffect } from "react";
import { useSelector, useDispatch } from 'react-redux';
import Alert from '@mui/material/Alert';
import {
  getCourses,
  selectCourses,
  selectLoadingState
} from '../../../app/slices/coursesSlice';
import styled from "styled-components";
// Components
import CourseTable from "../../../components/elements/CourseTable";

const withHooks = (WrappedComponent) => {
	return function(props) {
		const courses = useSelector(selectCourses);
    const isLoading = useSelector(selectLoadingState);
    const dispatch = useDispatch();

    useEffect(() => {
      if (isLoading === 'idle') {
        dispatch(getCourses());
      }
    }, [isLoading, dispatch]);
    
		return (<Courses courses={courses} />);
	};
}

class Courses extends Component {
  render() {
    const { courses } = this.props;

    return (
      <Wrapper id="courses">
        <div className="lightBg" style={{ padding: '60px 0' }}>
          <div className="container">
            <HeaderInfo>
              <h1 className="font40 extraBold greenColor">Financial Education</h1>
              <p className="font13 semiBold">
                Check out our <strong>FREE</strong> educational resources along with our premium trading courses.
              </p>
            </HeaderInfo>
            <TablesWrapper className="flexSpaceNull">
              { Array.isArray(courses) && courses.length > 0 ? courses.map((data) => (
                <TableBox courses={courses} key={data.id}>
                  <CourseTable
                    price={data.price}
                    title={data.title}
                    text={data.description}
                    url={data.url}
                    offers={data.offers}
                  />
                </TableBox>
              )) : <Alert severity="info">Courses not available at this time.</Alert>}
            </TablesWrapper>
          </div>
        </div>
      </Wrapper>
    );
  }
}

export default withHooks(Courses);

const Wrapper = styled.section`
  width: 100%;
`;
const HeaderInfo = styled.div`
  margin-bottom: 30px;
  @media (max-width: 860px) {
    text-align: center;
  }
`;
const TablesWrapper = styled.div`
  @media (max-width: 860px) {
    flex-direction: column;
    padding-bottom: 15px;
  }
`;
const TableBox = styled.div`
  width: ${(props) => ((100 / props.courses.length)) - 2}%;
  max-width: 48%;
  @media (max-width: 860px) {
    width: 100%;
    max-width: 370px;
    margin: 0 auto;
  }
`;