import { React, Component } from 'react';
import styled from 'styled-components';
import Copyright from '../utils/Copyright';
import { Divider } from '@mui/material';

class Footer extends Component {
  render() {
    const isVersionTwo = false;
    return (
      <Wrapper>
        <div className="darkBg">
          <div className="container">
            <InnerWrapper className="flexSpaceCenter">
              <Column style={{ marginLeft: "0px" }}>
                <Heading className="font20 extraBold">CONNECT WITH US</Heading>
                <a className="footer-link" href="https://instagram.com/FlexinFinancials">Instagram</a>
                <a className="footer-link" href="https://youtube.com/FlexinFinancials">YouTube</a>
                <a className="footer-link" href="https://www.tiktok.com/@flexin_financials">TikTok</a>
                <a className="footer-link" href="https://twitter.com/FlexinFinancial">Twitter</a>
              </Column>
              <Column>
                <Heading className="font20 extraBold">FREE RESOURCES</Heading>
                <a className="footer-link"
                  href="https://flexinfinancials.gumroad.com/l/starterpack">
                  Stock Market Starter Pack
                </a>
                <a className="footer-link" href="https://tos.mx/y1uxLL1">Thinkorswim Layout</a>
              </Column>
              <Column>
                <Heading className="font20 extraBold">STOCK PLATFORMS</Heading>
                <a className="footer-link" href="https://join.robinhood.com/camerot-bc285c">Robinhood</a>
                <a className="footer-link"
                  href="https://www.webull.com/activity?inviteCode=4ycbtzeXRfnq&source=invite_gw&inviteSource=wb_oversea">
                  WeBull
                </a>
                <a className="footer-link" href="https://m1.finance/9H2SqmvGLnXE">M1 Finance</a>
              </Column>
              { isVersionTwo ?
              <Column>
                <Heading className="font20 extraBold">LEGAL</Heading>
                <a className="footer-link" href="#">Privacy Policy</a>
                <a className="footer-link" href="#">Terms of Service</a>
                <a className="footer-link" href="#">Disclaimer</a>
              </Column>
              : null}
            </InnerWrapper>
            <Divider />
            <LowerWrapper className="flexSpaceCenter">
              <StyledP className="whiteColor font13 semiBold"><Copyright /></StyledP>
              <StyledP className="whiteColor font13 semiBold">A Flexin Financials Company</StyledP>
            </LowerWrapper>
          </div>
        </div>
      </Wrapper>
    );
  }
}

export default Footer;

const Wrapper = styled.div`
  width: 100%;
`;
const InnerWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  padding: 60px 0 30px 0;
  @media only screen and (max-width: 859px) {
    flex-direction: column;
    align-items: center;
    padding: 60px 0 0 0;
  }
`;
const LowerWrapper = styled.div`
  padding: 30px 0;
  @media only screen and (max-width: 859px) {
    flex-direction: column;
  }
`;
const StyledP = styled.p`
  @media only screen and (max-width: 859px) {
    margin: 20px 0;
  }
`;
export const Column = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
  margin-left: 60px;
  @media only screen and (max-width: 859px) {
    flex-direction: column;
    text-align: center;
    margin-bottom: 30px;
    margin-left: 0px;
  }
`;
export const Heading = styled.p`
  color: #fff;
  margin-bottom: 30px;
  @media only screen and (max-width: 859px) {
    margin-bottom: 10px;
  }
`;